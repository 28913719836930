import React from "react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CartContext from "../../store/CartContext";
import styled from "styled-components";
import Modal from "../UI/Modal";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CartShowItemName from "./CartShowItemName";
import LoadingSpinner from "../UI/LoadingSpinner";
import ReportIcon from "@mui/icons-material/Report";

const Cart = (props) => {
  const tableNumber = props.tableNumber;
  const params = useParams();
  const token = params.token;
  const header = {
    "Content-Type": "application/json",
    token: "Bear " + token,
  };
  const cartCtx = useContext(CartContext);
  const totalAmount = `$${cartCtx.totalAmount}`;
  const hasItems = cartCtx.items.length > 0;

  const [cartTotal, setCartTotal] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [didSubmit, setDidSubmit] = useState(false);
  const [httpError, setHttpError] = useState(false);

  useEffect(() => {
    calculateCartItemPrice();
  }, []);

  const cartItemRemoveHandler = (id) => {
    cartCtx.removeItem(id);
  };

  const calculateCartItemPrice = () => {
    let cTotal = 0;
    cartCtx.items.map((item, index) => {
      let totalAmount = 0;
      totalAmount = item.price * item.quantity;
      item.options.map((opt, optidx) => {
        totalAmount += +opt.price;
      });
      item.specials.map((spc, spcidx) => {
        totalAmount += +spc.price;
      });
      cartCtx.items[index].totalAmount = totalAmount;
      cTotal += totalAmount;
      cartCtx.totalAmount = cTotal;
    });
    setCartTotal(+cTotal);
  };

  const cartItemAddHandler = (item) => {
    cartCtx.addItem({ ...item, quantity: 1 });
  };

  const checkoutHandler = async () => {
    setIsSubmitting(true);

    cartCtx.tableNumber = tableNumber;
    cartCtx.token = token;
    const sCart = JSON.stringify(cartCtx);

    console.log(
      JSON.stringify({
        cart: sCart,
      })
    );

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/checkout",
        {
          method: "POST",
          headers: header,
          body: JSON.stringify({
            cart: sCart,
          }),
        }
      );
      if (!response.ok) throw new Error("訂單提交失敗，請重試！");

      const data = await response.json().catch((error) => {
        setIsSubmitting(false);
        setDidSubmit(false);
        setHttpError(error.message);
      });

      setTimeout(function () {
        setIsSubmitting(false);
        setDidSubmit(true);
        cartCtx.clearCart();
      }, 2000);
    } catch (e) {
      setIsSubmitting(false);
      setDidSubmit(false);
      setHttpError(e.message);
    }
  };

  const completeHandler = () => {
    window.location.reload();
  };

  const retrySubmitHandler = () => {
    setIsSubmitting(false);
    setDidSubmit(false);
    setHttpError("");
  };

  //console.log(cartCtx);
  // step to loop through options:
  // cartCtx.items.map((item) => {
  //   item.options.map((i) => {
  //     console.log("Options:");
  //     i.selected.map((j) => {
  //       console.log(j.name);
  //     });
  //   });
  // });

  const cartModalContent = (
    <CartContainer>
      <CartNav>
        <CartNavLeft>**餐飲價錢將於落單後扣減**</CartNavLeft>
        <CartNavRight>
          <Button variant="outlined" onClick={props.onClose}>
            繼續點餐
          </Button>
        </CartNavRight>
      </CartNav>
      <Divider>已選項目</Divider>
      <CartContent>
        <CartItems>
          {cartCtx.items.map((item) => (
            <>
              <CartItem key={item.id}>
                <CartItemDelete
                  onClick={cartItemRemoveHandler.bind(null, item.id)}
                >
                  <DeleteForeverIcon color="primary" />
                </CartItemDelete>
                <CartItemName>
                  <CartShowItemName item={item} />
                  <CartSpecials>
                    {item.specials.map((spec) => spec.name)}
                  </CartSpecials>
                  <CartDrinkMessage>
                    {item.settype === "3"
                      ? "(學生堂食車仔面:汽水免費,熱飲+$3,凍飲+$5)"
                      : ""}
                  </CartDrinkMessage>
                </CartItemName>
                <CartItemQuantity>{item.quantity}</CartItemQuantity>
                <CartItemAmount>{`$ ${item.totalAmount.toFixed(
                  1
                )}`}</CartItemAmount>
              </CartItem>
              <Divider />
            </>
          ))}
        </CartItems>
      </CartContent>
      <CartBottom>
        {hasItems && (
          <CheckOutButton onClick={checkoutHandler} variant="outlined">
            <TouchAppIcon /> 落單
          </CheckOutButton>
        )}
      </CartBottom>
      <CartMessage>**跟餐飲品價錢將於落單後扣減**</CartMessage>
    </CartContainer>
  );

  const isSubmittingModalContent = (
    <ModalInfoContent>
      <Submitting>
        <LoadingSpinner />
      </Submitting>
    </ModalInfoContent>
  );

  const didSubmitModalContent = (
    <ModalInfoContent>
      <SuccessText>食品已落單，請耐心等候 </SuccessText>
      <CloseCart variant="outlined" onClick={completeHandler}>
        完成
      </CloseCart>
    </ModalInfoContent>
  );

  const errorSubmitting = (
    <ModalInfoContent>
      <Submitting>
        <ReportIcon />
        <ErrorText>{httpError}</ErrorText>
        <Button variant="outlined" onClick={retrySubmitHandler}>
          重試
        </Button>
      </Submitting>
    </ModalInfoContent>
  );

  return (
    <Modal onClose={props.onClose}>
      {!httpError && !isSubmitting && !didSubmit && cartModalContent}
      {!httpError && isSubmitting && isSubmittingModalContent}
      {!httpError && !isSubmitting && didSubmit && didSubmitModalContent}
      {httpError && errorSubmitting}
    </Modal>
  );
};

export default Cart;

const ModalContent = styled.div``;

const Submitting = styled.div`
  text-align: center;
`;

const ModalInfoContent = styled.div`
  width: 100%;
  text-align: center;
  height: 45vh;
  padding-top: 100px;
`;

const ErrorText = styled.div`
  font-weight: bold;
  height: 60px;
  color: red;
`;

const SuccessText = styled.div`
  font-weight: bold;
  height: 60px;
`;

const CloseCart = styled(Button)`
  width: 100%;
`;

const CartSpecials = styled.div`
  font-size: 13px;
  color: #aca6a6;
`;
const CartDrinkMessage = styled.div`
  font-size: 15px;
  color: #4a92ef;
`;
const CartItems = styled.div``;

const CartItem = styled.li`
  display: flex;
`;

const CartItemDelete = styled.div`
  padding-top: 5px;
  flex: 1;
  text-align: left;
`;

const CartItemName = styled.div`
  flex: 5;
  text-align: left;
  padding-top: 5px;
  min-height: 40px;
`;

const CartItemQuantity = styled.div`
  text-align: center;
  flex: 1;
  padding-top: 7px;
`;

const CartItemAmount = styled.div`
  flex: 2;
  text-align: right;
  justify-content: flex-end;
  padding-top: 5px;
`;

const CartContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const CartNav = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
`;

const CartContent = styled.div`
  width: 100%;
  height: 60vh;
  overflow: scroll;
`;

const CartBottom = styled.div`
  width: 100%;
  height: 40px;
  text-align: center;
  margin-top: auto;
  align-self: flex-end;
  padding-top: 10px;
`;

const CartNavLeft = styled.div`
  height: 100%;
  flex: 1;
  font-size: 11px;
  color: #555454;
`;
const CartNavRight = styled.div`
  flex: 1;
  text-align: right;
`;

const CheckOutButton = styled(Button)`
  width: 80%;
`;

const CartMessage = styled.div`
  height: 100%;
  font-size: 13px;
  text-align: right;
  color: #ff0000;
  width: 80%;
`;
