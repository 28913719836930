import React from "react";
import styled from "styled-components";
import {
  CloudOffTwoTone,
  CloudDoneOutlined,
  Language,
} from "@mui/icons-material";

const Navbar = (props) => {
  const isShopOnline = props.isShopOnline;

  return (
    <Containr>
      <Wrapper>
        <Left>
          <StatusCointainer>
            {isShopOnline && (
              <>
                <CloudDoneOutlined sx={{ color: "green" }} />
                <div> 營業中 </div>
              </>
            )}
            {!isShopOnline && (
              <>
                <CloudOffTwoTone sx={{ color: "red" }} />
                <div> 店舖未上線 </div>
              </>
            )}
          </StatusCointainer>
        </Left>
        <Center>
          <Logo>有間車仔麵</Logo>
        </Center>
        <Right>
          <LanguageContainer>
            <Language sx={{ fontSize: 18 }}></Language>繁
          </LanguageContainer>
        </Right>
      </Wrapper>
    </Containr>
  );
};

export default Navbar;
const Containr = styled.div`
  height: 35px;
`;
const Wrapper = styled.div`
  padding: 5px 5px;
  display: flex;
  justify-content: space-between;
`;
const Left = styled.div`
  flex: 1;
`;
const StatusCointainer = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const Center = styled.div`
  flex: 1;
  text-align: center;
`;
const Logo = styled.h1`
  font-weight: bold;
`;

const Right = styled.div`
  flex: 1;
`;

const LanguageContainer = styled.span`
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
